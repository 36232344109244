import React, { useState } from "react"

import { Link } from "gatsby"
import ReactTooltip from 'react-tooltip';

// lists a bunch of platforms (highlights current one) on any platform page (for easy navigation)
const PlatformIcons = ({platforms, platform}) => {
  const countToShow = 6;
  const [showCount, setShowCount] = useState(6);


  var platformsToShow = platforms
    .filter(p => p.slug !== 'independent')
    .filter(p => p.count >= 1)
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
    .filter((t, i) => i < showCount);
  // console.log(platformsToShow)

  if (!platformsToShow.map(p => p.slug).includes(platform.slug) && platform.slug !== 'independent'){
    platformsToShow[countToShow - 1] = platform;
    // platformsToShow.push(platform)
  }

  // return ''

  return (
    <section aria-labelledby="platform-icons">
      <div className="">
        {/*<div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex items-center justify-between">
            <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
              Platforms
            </h2>
          </div>
        </div>*/}
        <div className={`mt-2`}>
          <ReactTooltip id={`platforms-tooltip`} place="top" effect="solid" backgroundColor="black" textColor="white"
            className=""
          />
          <div className="flex flex-wrap">
            {platformsToShow.map(p => (
              <Link to={`/platforms/${p.slug}/`} key={p.slug}
                className={`p-1 border-2 ${platform && platform.slug === p.slug ? 'border-rose-500' : 'border-gray-100 hover:border-gray-200'} bg-white rounded-full relative cursor-pointer mr-3 mb-2`}
                data-for={`platforms-tooltip`} data-tip={p.name}
              >
                <div className="bg-white h-6 w-6 rounded-full overflow-hidden">
                  <img src={p.logoSmall} className="h-full w-full h-8 w-8 object-contain"/>
                </div>

                <div className={`h-5 w-5 p-0.5 absolute -right-2 -bottom-2 rounded-full text-center text-xs  ${platform && platform.slug === p.slug ? 'text-gray-500 font-semibold bg-gray-100' : 'text-gray-400 bg-gray-50'}`}>
                  <div>{p.count}</div>
                </div>
              </Link>
            ))}


            <Link to={`/platforms/`}// key={p.slug}
                className={`p-1 border-2 border-gray-100 bg-white rounded-full relative cursor-pointer hover:border-gray-200 mr-3 mb-2 bg-gray-50`}
                data-for={`platforms-tooltip`} data-tip={`All ${platforms.length} Platforms`}
              >
                <div className="bg-white h-6 w-6 rounded-full overflow-hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg>

                </div>

                {/*<div className={`h-5 w-5 p-0.5 absolute -right-2 -bottom-2 rounded-full text-center text-xs text-gray-400 bg-gray-50`}>
                  <div>{platforms.length - platformsToShow.length}</div>
                </div>*/}
              </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PlatformIcons;

