import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'

import SEO from "../components/common/seo"
import Layout from "../components/common/layout"
// import TopicList from "../components/topic/topiclist"
import TopicCard from "../components/topic/topiccard"
import TopicFilters from "../components/topic/filters"
import CommunitiesTable from "../components/community/communitiestable"
import PlatformIcons from "../components/platform/platformIcons"

import { trackEvent } from "../utils/tracking"
import { sortCommunities } from '../utils/communities'

const PlatformPage = ({ data, classes, pageContext }) => {
  const platform = data.platform;
  const showCommunitySidebar = true;
  const showIncrement = 3;
  const [topicsToShow, setTopicsToShow] = useState(showIncrement);  // default topics to show, until user expands

  const count = data.communities.totalCount;
  const communities = data.communities.nodes.sort((a,b) => (a.members < b.members) ? 1 : ((b.members < a.members) ? -1 : 0));

  const [sizeFilters, setSizeFilters] = useState([]);
  const sortOptions = ["Popular", "Ratings", "Largest", "Newest"];
  const [sort, setSort] = useState(sortOptions[0]);


  // get topics that this platform covers
  var topicCounts = {};  // key is slug, value is count
  communities.map(c => c.topics).forEach((slugs) => {
    const topics = slugs ? slugs.split(',') : [];
    topics.forEach(t => {
      if (topicCounts[t]){
        topicCounts[t]['count'] += 1;
      } else if (data.topics.nodes.filter(topic => topic.slug === t)[0]) {
        topicCounts[t] = {'topic': data.topics.nodes.filter(topic => topic.slug === t)[0] , 'count': 1};
      }
    }) 
  });

  // get tools for this community
  var platformTools = data.allTools.nodes.filter(tool => tool.platforms && tool.platforms.split(',').includes(platform.slug));
  platformTools = platformTools.sort((a,b) => (a.promoted && !b.promoted) ? -1 : ((!a.promoted && b.promoted) ? 1 : 0));
  const [countToolsToShow, setCountToolsToShow] = useState(4);

  // get the redirect url
  var urlForRedirect = platform.link;
  var linkDisplay = ''
  if (urlForRedirect){
    if (urlForRedirect.includes('?')){
      urlForRedirect += '&ref=thehiveindex.com&utm_source=thehiveindex.com'
    } else {
      urlForRedirect += '?ref=thehiveindex.com&utm_source=thehiveindex.com'
    }
    linkDisplay = platform.link.replace('www.', '').replace(`https://`, '').replace(`http://`, '').split('?')[0]
  }

  // sizes of communities
  var sizes = ["Tiny", "Small", "Medium", "Large", "Huge", "Massive", "Gigantic", "Unknown"].map(s => {
    return {
      'name': s,
      'count': communities.filter(c => c.memberSize === s).length
    }
  });

  // filter communiites based on filter
  var filteredCommunities = communities;
  if (sizeFilters.length){
    filteredCommunities = filteredCommunities.filter(c => sizeFilters.map(f => f.name).includes(c.memberSize))
  }

  // sort them
  filteredCommunities = sortCommunities(sort, filteredCommunities)

  const title = `Best ${platform.groupNamePlural ? platform.groupNamePlural : `${platform.name} Communities`} to Join`

  var description = `Discover ${count || ''} online communities on ${platform.name}`;
  if (filteredCommunities.length > 0){
    description += ', like '
    description += filteredCommunities.slice(0, 3).map(c => c.name).join(', ')
    description += ' and more.'
  } else {
    description += '.'
  }

  return (
    <Layout pageTitle={title} shareButtons={true} noBackground={true} enableShare={true} >
      <SEO
        title={title}
        description={description}
        image={platform.logoBig}
        generateImage={true} path={`/platforms/${platform.slug}/`}
        schema={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": platform.name,
          "url": `https://thehiveindex.com/platforms/${platform.slug}/`,
          "logo": platform.logoSmall,
        }}
        schema={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "name": title,
          "description": description,
          "url": `https://thehiveindex.com/platforms/${platform.slug}/`,
          "isPartOf": {
            "@type": "WebSite",
            "name": "Hive Index",
            "url": "https://thehiveindex.com/"
          },
          "provider": {
            "@type": "Organization",
            "@id": "Organization",
            "name": "Hive Index"
          },
          "mainEntity": 
          [
            {
              "@type": "DataCatalog",
              "name": `${platform.name} Communities`,
              "dataset": communities.slice(0, 20).map((c) => {
                return {
                  "@type": "Dataset",
                  "name": c.name,
                  "creator": {
                    "@id": "Organization"
                  },
                  "description": `${c.name} is an online community${c.platform ? ` on ${c.platform}` : ``} for ${c.topics.split(',').join(', ')}. ${c.description}`,
                  "url": `https://thehiveindex.com/communities/${c.slug}/`,
                  "image": c.logo ? {
                      "@type": "ImageObject",
                      "url": c.logo,
                      "contentUrl": c.logo
                  } : null,
                  "isPartOf": `https://thehiveindex.com/platforms/${platform.slug}/`,
                  "license": ["https://thehiveindex.com/privacy-policy/"],
                  "includedInDataCatalog": 
                    {
                      "@type": "DataCatalog",
                      "name": `${platform.name} Communities`,
                      "url": `https://thehiveindex.com/platforms/${platform.slug}/`,
                    }
                  }
                }
              )
            },
            {
              "@type": "ItemList",
              "name": `Topics Discussed in ${platform.name} Communities`,
              "itemListElement":
                Object
                  .keys(topicCounts)
                  .map(key => topicCounts[key])
                  .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
                  .map(t => {
                    return {
                      "@type": "ListItem",
                      "name": t.name,
                      "url": `https://thehiveindex.com/topics/${t.slug}/platform/${platform.slug}/`,
                      "image":
                        {
                          "@type": "ImageObject",
                          "url": t.image,
                          "contentUrl": t.image,
                        }
                    }
                  })
            },
          ]}}
      />

      {/* Main 3 column grid */}
      <div className={`grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8`}>
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">

          <div className="rounded-lg bg-white overflow-hidden shadow p-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex items-center justify-between">
                <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
                  <div>Top {platform.name} Communities</div>
                  <span className="opacity-50 text-sm ml-4 font-semibold">{data.communities.totalCount}</span>
                </h2>
                {/*<Link to="/submit/">
                  <button
                    type="button"
                    className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                  >
                    <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span>New</span>
                  </button>
                </Link>*/}
              </div>
            </div>

            <div className="flex items-center mt-2">
              <PlatformIcons platforms={data.allPlatforms.nodes} platform={platform} />

              {count > 10 ? (
                <div className="ml-auto">
                  <TopicFilters
                    // topic={topic} platforms={platforms} features={features}
                    // tags={tags} tagFilter={tagFilter} setTagFilter={setTagFilter}
                    sizes={sizes} sizeFilters={sizeFilters} setSizeFilters={setSizeFilters}
                    sort={sort} setSort={setSort} sortOptions={sortOptions}
                  />
                </div>
              ) : ''}
            </div>

            {count > 0 ? (
              <div className="mt-1">
                <CommunitiesTable communities={filteredCommunities} showPlatform={false} showTopics={true} linkModal={true} showRankings={true} />
              </div>
            ) : (
              <div className="mt-1">
                <div style={{padding: '20px 0 0', textAlign: 'center', fontWeight: '500', color: '#95a5a6'}}>
                  There are no online communities on this platform yet. Do you know one? Please <Link to="/submit/">submit it</Link>!
                </div>
              </div>
            )}
          </div>
        </div>


        {/* Right column */}
        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="platform-info">
            <div className="rounded-lg bg-white overflow-hidden shadow p-6">
              
              <div className="flex">
                {/*<div className="flex-shrink-0 mr-4">
                  {platform.logoSmall ? (
                    <img className="mx-auto h-24 w-24 rounded-md object-contain" src={platform.logoSmall} alt={platform.name} />
                  ) : ''}
                </div>*/}

                <div className="block">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex items-center justify-between">
                      <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                        What are {platform.groupNamePlural}?
                      </h2>
                    </div>
                  </div>

                  <p className="mt-1 text-gray-500">
                    {platform.description}
                  </p>
                </div>
              </div>

              {platform.descriptionForCommunities ? (
                <React.Fragment>
                  <div className="relative mt-6">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex items-center justify-between">
                      <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                        {platform.name} for community builders
                      </h2>
                    </div>
                  </div>

                  {platform.descriptionForCommunities ? (
                    <p className="mt-1 text-gray-500 whitespace-pre-wrap">
                      {platform.descriptionForCommunities}
                    </p>
                  ) : ''}

                  {platform.promotionText ? (
                    <p className="mt-2 text-green-400 font-bold">
                      {platform.promotionText}
                    </p>
                  ) : ''}

                  {urlForRedirect ? (
                    <div className="mt-4">
                      <a href={urlForRedirect} target="_blank" rel="noopener" onClick={() => {
                        // track event
                        trackEvent('PlatformRedirect', {
                          'event_label': platform.name,
                          // 'type': 'platform',
                          'slug': platform.slug,
                          'name': platform.name,
                        })
                      }}
                        className="flex justify-center items-center px-4 py-2 border border-rose-600 text-white bg-rose-500 hover:bg-rose-600 shadow-sm text-sm rounded-sm text-white"
                      >
                        <div className="mr-4">
                          <div className="opacity-100 font-medium max-w-xs truncate">{linkDisplay}</div>
                          <div className="opacity-75 text-xs font-semibold">Go to community platform</div>
                        </div>
                        <div className="ml-auto">
                          →
                        </div>
                      </a>
                    </div>
                  ) : ''}
                </React.Fragment>
              ) : ''}
            </div>
          </section>

          {Object.keys(topicCounts).length > 1 ? (
             <section aria-labelledby="platform-info">
              <div className="rounded-lg bg-white overflow-hidden shadow p-6">
                <div className="">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex items-center justify-between">
                      <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                        Popular Topics on {platform.name}
                      </h2>
                      <span className="text-sm ml-4 pl-2 font-semibold bg-white">
                        <span className="opacity-50">{Object.keys(topicCounts).length}</span>
                      </span>
                    </div>
                  </div>

                  <div className="mt-1 text-gray-500">
                    <ul className={`mt-2 grid grid-cols-1 gap-y-4`}>
                      {Object
                        .keys(topicCounts)
                        .map(key => topicCounts[key])
                        .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
                        .map((t, i) =>
                      (
                        <TopicCard key={t.topic.slug + i} platform={platform} topic={t.topic} countOverride={t.count} mini={true} hidden={i > topicsToShow - 1}/>
                      ))}
                    </ul>
                  
                    {topicsToShow < Object.keys(topicCounts).length ? (
                      <div className="pt-4 mx-auto text-center">
                        <div className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
                          onClick={() => setTopicsToShow(topicsToShow + showIncrement)}
                        >
                          Show More →
                        </div>
                      </div>
                    ) : ''}
                  </div>
                </div>
              </div>
            </section>
          ) : ''}

          {platformTools.length ? (
            <section aria-labelledby="platform-info">
              <div className="rounded-lg bg-white overflow-hidden shadow p-6">
                <div className="">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex items-center justify-between">
                      <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                        {platform.name} Community Tools
                      </h2>
                      <span className="text-sm ml-4 pl-2 font-semibold bg-white">
                        <span className="opacity-50">{platformTools.length}</span>
                      </span>
                    </div>
                  </div>

                  <div className="mt-1 text-gray-500">

                    <div className={`mt-2 space-y-4`}>          
                      {platformTools.filter((t, i) => i < countToolsToShow).map((t, index) => (
                        <a className="flex" key={t.slug} href={t.link} target="_blank" rel="nofollow"
                          onClick={() => {
                            // track event
                            trackEvent('Platform', {
                              'event_label': t.name,
                              'slug': t.slug,
                              'name': t.name,
                              'page': 'ToolsList',
                              'index': index,
                            })
                          }}
                        >
                          {t.logo ? (
                            <div className="mr-3 h-12 w-12 flex-shrink-0">
                              <img src={t.logo} className="h-12 w-12 rounded-md mr-3 object-contain" />
                            </div>
                          ) : ''}

                          <div>
                            <div className={`block cursor-pointer font-medium text-rose-500 hover:underline`}>
                              {t.name}

                              {t.promoted ? (
                                <span className="ml-2 text-xs bg-yellow-400 font-semibold text-white rounded-md px-2 py-0.5">Featured</span>
                              ) : ''}
                            </div>
                            <div className="mt-0 block text-sm text-gray-500 line-clamp-2">
                              {t.headline}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>

                    {platformTools.length > countToolsToShow ? (
                      <div className="pt-4 mx-auto text-center">
                        <div onClick={() => setCountToolsToShow(platformTools.length)}
                          className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer">
                          More Tools for {platform.name} →
                        </div>
                      </div>

                    ) : ''}
                    
                    <div className="pt-2 mx-auto text-center">
                      <Link to={`/tools/`}
                        className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer">
                        All Community Tools →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : ''}
        </div>

      </div>

    </Layout>
  )
}

export const platformPageQuery = graphql`
  query PlatformPage($slug: String, $communitySlugs: [String]) {
    site {
      siteMetadata {
        title
      }
    }
    platform: googleSpreadsheetSourcePlatforms(slug: {eq: $slug}) {
      name
      slug
      count
      logoSmall
      logoBig
      groupName
      groupNamePlural
      description
      descriptionForCommunities
      link
      promoted
    }
    communities: allGoogleSpreadsheetSourceCommunities(filter: {slug: {in: $communitySlugs}, removed: {ne: true}}, sort: {fields: members, order: DESC}) {
      totalCount
      nodes {
        name
        slug
        members
        memberSize
        countFeatures
        order
        established
        platform
        description
        topics
        logo
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
        reviewsPositive
        views
        visitors
        redirects
        gold
        sponsored
      }
    }
    topics: allGoogleSpreadsheetSourceTopics(filter: {count: {gt: 0}}) {
      nodes {
        slug
        name
        count
        image
        people
      }
    }
    allPlatforms: allGoogleSpreadsheetSourcePlatforms(sort: {fields: name, order: ASC}) {
      nodes {
        slug
        name
        count
        logoBig
        logoSmall
        groupName
        groupNamePlural
        promoted
        descriptionForCommunities
      }
    }
    allTools: allGoogleSpreadsheetSourceTools(filter: {removed: {ne: true}}) {
      nodes {
        order
        slug
        name
        headline
        categories
        platforms
        logo
        link
        promoted
      }
    }
  }
`

export default PlatformPage

